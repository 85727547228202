<template>
  <div id="paymentDetails">
    <header-nav :content="`${$t('pages_router_007')} / ${ $t('pages_router_028')}`" />
    <div class="common-card-body">
      <a-tabs
        v-model:activeKey="activeKey"
        class="header-tabs"
      >
        <template #rightExtra>
          <a-button
            v-if="themeData.electronicReceipt"
            type="primary"
            style="margin-right: 18px"
            :loading="downLoading"
            @click="handleExportBatch"
          >
            <template #icon><bars-outlined /></template>
            {{ downLoading ? $t('common_text_023') : $t('pages_pay_120') }}
          </a-button>
          <a-button
            type="primary"
            ghost
            style="min-width: 100px"
            :loading="exportLoading"
            @click="handleExport"
          >
            <template #icon><download-outlined /></template>
            {{ exportLoading ? $t('common_text_023') : $t('common_text_033') }}
          </a-button>
        </template>
        <a-tab-pane
          key="outbound"
          :tab="$t('pages_pay_110')"
        >
          <pay-outbound ref="outboundRef" :is-open-pobo="isOpenPobo" :cur-list="curList" :theme-data="themeData" />
        </a-tab-pane>
        <a-tab-pane
          key="settles"
          :tab="$t('pages_pay_111')"
        >
          <pay-settles ref="settlesRef" :cur-list="curList" :theme-data="themeData" />
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>
<script>
import { ref, reactive, toRefs, inject, watch, nextTick } from 'vue'
import HeaderNav from '@/components/header-nav'
import PayOutbound from './pay-outbound'
import PaySettles from './pay-settles'
import { _pay, _receive } from '@/api'
export default {
  name: 'PaymentDetails',
  components: {
    'header-nav': HeaderNav,
    'pay-outbound': PayOutbound,
    'pay-settles': PaySettles
  },
  setup() {
    const state = reactive({
      themeData: inject('$themeData'),
      activeKey: 'outbound',
      exportLoading: false,
      downLoading: false,
      isOpenPobo: false,
      curList: []
    })

    const outboundRef = ref(null)
    const settlesRef = ref(null)

    watch(() => state.activeKey,
      value => {
        nextTick(() => {
          if (value === 'settles' && settlesRef.value) settlesRef.value.loadSettlesList()
          if (value === 'outbound' && outboundRef.value) outboundRef.value.loadOutboundList()
        })
      },
      { immediate: true }
    )

    const handleExport = async () => {
      state.exportLoading = true
      if (state.activeKey === 'outbound') await outboundRef.value.exportDetail()
      else await settlesRef.value.exportDetail()
      state.exportLoading = false
    }

    const handleExportBatch = async () => {
      state.downLoading = true
      if (state.activeKey === 'outbound') await outboundRef.value.exportElectronicReceipt()
      else await settlesRef.value.exportElectronicReceipt()
      state.downLoading = false
    }

    const loadMerchantInfo = async () => {
      const res = await _pay.findByMerchantKycEnrol()
      if (res.data.succ) {
        const { data } = res.data
        state.isOpenPobo = data.products ? data.products.indexOf('POBO') !== -1 : false
      }
    }

    const loadCurList = async () => {
      const res = await _receive.getAllCurrency()
      if (res.data.succ) {
        state.curList = res.data.data
      }
    }

    const init = () => {
      loadCurList()
      loadMerchantInfo()
    }

    init()

    return {
      outboundRef,
      settlesRef,
      handleExport,
      handleExportBatch,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#paymentDetails {
  .common-card-body {
    padding: 8px 0;
    .header-tabs:deep(.ant-tabs-nav) {
      padding: 0 18px;
    }
  }
}
</style>

