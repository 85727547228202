<template>
  <div id="outboundDetails">
    <a-modal
      width="53%"
      :visible="visible"
      :title="$t('pages_pay_104')"
      :mask-closable="false"
      @cancel="$emit('update:visible', false)"
    >
      <a-spin :spinning="spinLoading">
        <div class="detail-body-i">
          <div class="body-list">
            <div class="title">
              <money-collect-filled class="ic-transact" />
              <span>{{ infoData.title }}</span>
            </div>
            <div class="area-list">
              <div
                v-for="(item, index) in infoData.list"
                :key="index"
                class="area"
              >
                <span style="font-weight: 500">{{ item.name }}</span>
                <div class="text">
                  <img v-if="item.img" :src="item.img">
                  <a-tooltip v-if="['remitAmount', 'arrivalAmount', 'feeAmt'].includes(item.attr)" :title="item.value">
                    <div class="ellipsis" style="width: 100px">{{ item.value }}</div>
                  </a-tooltip>
                  <a-tooltip v-else-if="item.value" :title="item.value">
                    <div class="ellipsis">{{ item.value }}</div>
                  </a-tooltip>
                  <span v-else />
                </div>
              </div>
            </div>
            <div v-if="infoData.subList.length !== 0" class="area-list sub-list">
              <div
                v-for="(item, index) in infoData.subList"
                :key="index "
                class="area"
              >
                <span>{{ item.name }}</span>
                <div class="text">
                  <a-tooltip v-if="item.value" :title="item.value">
                    <div class="ellipsis">{{ item.value }}</div>
                  </a-tooltip>
                  <span v-else />
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-spin>
      <template #footer>
        <a-button type="primary" @click="$emit('update:visible', false)">{{ $t('common_text_024') }}</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import { reactive, toRefs, watch } from 'vue'
import { getTradeStatusName, getCountryLogo, parseMoney } from '@/util'
import { _pay } from '@/api'
import i18n from '@/locale'
export default {
  name: 'OutboundDetails',
  props: {
    visible: {
      type: Boolean,
      default: () => { return false }
    },
    applyNo: {
      type: String,
      default: () => { return '' }
    }
  },
  setup (props) {
    const state = reactive({
      spinLoading: false,
      titles: [],
      infoData: {
        title: i18n.global.t('pages_pay_084'),
        list: [],
        subList: []
      }
    })

    watch(() => props.applyNo,
      () => loadDetails()
    )

    const loadDetails = async () => {
      state.spinLoading = true
      const res = await _pay.getOutboundDetail({ applyNo: props.applyNo })
      if (res.data.succ) {
        const list = []
        const { data } = res.data
        const listData = data.fixedTitles.map(item => {
          let img = ''
          let value = data.outboundPayment[item.key]
          let attr = ''
          if (item.key === 'remitAmt') {
            attr = 'remitAmount'
            img = data.outboundPayment['remitAmt'] ? getCountryLogo(data.outboundPayment['remitCurrency']) : null
            value = data.outboundPayment['remitAmt'] ? `${data.outboundPayment['remitCurrency']} ${parseMoney(data.outboundPayment['remitAmt'])}` : '-'
          }
          if (item.key === 'arrivalAmt') {
            attr = 'arrivalAmount'
            img = data.outboundPayment['arrivalAmt'] ? getCountryLogo(data.outboundPayment['arrivalCurrency']) : null
            value = data.outboundPayment['arrivalAmt'] ? `${data.outboundPayment['arrivalCurrency']} ${parseMoney(data.outboundPayment['arrivalAmt'])}` : '-'
          }
          if (item.key === 'feeAmt') {
            attr = 'feeAmt'
            img = data.outboundPayment['feeAmt'] ? getCountryLogo(data.outboundPayment['feeCurrency']) : null
            value = data.outboundPayment['feeAmt'] ? `${data.outboundPayment['feeCurrency']} ${parseMoney(data.outboundPayment['feeAmt'])}` : '-'
          }
          if (item.key === 'amountMark') {
            value = getTradeStatusName(data.outboundPayment['amountMark'], 'amountMark')
          }
          if (item.key === 'bankAcctType') {
            value = getTradeStatusName(data.outboundPayment['bankAcctType'], 'subjectType')
          }
          return Object.assign({}, item, {
            img,
            value,
            attr,
            name: item.title
          })
        })
        const subList = data.dynamicTitles.map(item => {
          return Object.assign({}, item, {
            name: item.title,
            value: data.outboundPayment[item.key]
          })
        })
        for (const li of listData) {
          if (!['remitCurrency', 'arrivalCurrency', 'feeCurrency'].includes(li.key)) {
            list.push(li)
          }
        }
        state.infoData.list = list
        state.infoData.subList = subList
      }
      state.spinLoading = false
    }

    return {
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../styles/detail-form.less';
</style>

