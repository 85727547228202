<template>
  <div id="paySettles">
    <div class="search-form ant-round-form">
      <div class="part flex-align">
        <span class="part-label">{{ $t('pages_pay_106') }}: </span>
        <a-input
          v-model:value="searchQuery.applyNo"
          allow-clear
          @pressEnter="loadSettlesList(1)"
          @blur="loadSettlesList(1)"
        />
      </div>
      <div class="part flex-align">
        <span class="part-label">{{ $t('pages_pay_112') }}: </span>
        <a-input
          v-model:value="searchQuery.batchNo"
          allow-clear
          @pressEnter="loadSettlesList(1)"
          @blur="loadSettlesList(1)"
        />
      </div>
      <div class="part flex-align">
        <span class="part-label" style="width: 180px">{{ $t('pages_pay_113') }}: </span>
        <a-input
          v-model:value="searchQuery.merSingleNo"
          allow-clear
          @pressEnter="loadSettlesList(1)"
          @blur="loadSettlesList(1)"
        />
      </div>
      <div class="part flex-align">
        <span class="part-label">{{ $t('pages_pay_071') }}: </span>
        <a-select
          v-model:value="searchQuery.remitCurrency"
          allow-clear
          show-search
          option-label-prop="label"
          option-filter-prop="label"
          @change="loadSettlesList(1)"
        >
          <a-select-option
            v-for="item in curList"
            :key="item.currencyCode"
            :value="item.currencyCode"
            :label="`${item.currencyCode} - ${item.currencyName}`"
          >
            <img :src="getCountryLogo(item.imgName || item.currencyCode)" class="select-img-country">
            <span>{{ item.currencyCode }} - {{ item.currencyName }}</span>
          </a-select-option>
        </a-select>
      </div>
      <div class="part flex-align">
        <span class="part-label">{{ $t('pages_pay_077') }}: </span>
        <a-range-picker
          v-model:value="searchQuery.dateOpts"
          format="YYYY/MM/DD"
          value-format="YYYY-MM-DD"
          @change="loadSettlesList(1)"
        />
      </div>
      <div class="part flex-align">
        <span class="part-label" style="width: 180px">{{ $t('pages_pay_115') }}: </span>
        <a-range-picker
          v-model:value="searchQuery.payDateOpts"
          format="YYYY/MM/DD"
          value-format="YYYY-MM-DD"
          @change="loadSettlesList(1)"
        />
      </div>
      <div class="part flex-align">
        <span class="part-label">{{ $t('pages_pay_114') }}: </span>
        <a-select
          v-model:value="searchQuery.status"
          allow-clear
          @change="loadSettlesList(1)"
        >
          <a-select-option
            v-for="status in statusOpts"
            :key="status.code"
            :value="status.code"
          >{{ status.name }}</a-select-option>
        </a-select>
      </div>
    </div>
    <div class="table-part">
      <a-table
        :scroll="{ x: 1500 }"
        :loading="loading"
        :pagination="false"
        :custom-header-row="() => { return {class: 'my-table-tr'}}"
        :columns="columns"
        :data-source="settles"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'edit'">
            <a-space style="width: 100%; justify-content: space-between">
              <a-button
                shape="round"
                size="small"
                type="link"
                @click="showDetail(record)"
              >{{ $t('pages_pay_119') }}</a-button>
              <a-button
                v-if="record.status === '02' && themeData.electronicReceipt"
                shape="round"
                size="small"
                type="primary"
                @click="downElectronicReceipt(record)"
              >{{ $t('pages_pay_116') }}</a-button>
            </a-space>
          </template>
        </template>
      </a-table>
      <table-page
        :total="total"
        :page-opts="searchQuery"
        @update-page="updatePage"
      />
    </div>
    <settles-details
      v-model:visible="visible"
      :apply-no="applyNo"
    />
  </div>
</template>
<script>
import { reactive, toRefs, computed } from 'vue'
import TablePage from '@/components/table-page'
import SettlesDetails from './components/details'
import { _pay } from '@/api'
import moment from 'moment'
import i18n from '@/locale'
import { getAttrStatusList, getTradeStatusName, getCountryLogo, parseMoney } from '@/util'
export default {
  name: 'PaySettles',
  components: {
    'table-page': TablePage,
    'settles-details': SettlesDetails
  },
  props: {
    curList: {
      type: Array,
      default: () => { return [] }
    },
    themeData: {
      type: Object,
      default: () => { return {} }
    }
  },
  setup() {
    const state = reactive({
      loading: true,
      visible: false,
      applyNo: '',
      searchQuery: {
        pageNum: 1,
        pageSize: 10,
        applyNo: '',
        batchNo: '',
        merSingleNo: '',
        remitCurrency: '',
        dateOpts: [moment().subtract(6, 'months').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        payDateOpts: []
      },
      total: 0,
      settles: [],
      columns: [
        { key: 'payeeName', dataIndex: 'payeeName', title: i18n.global.t('pages_pay_117'), width: 200, ellipsis: true },
        { key: 'remitAmount', dataIndex: 'remitAmountText', title: i18n.global.t('pages_pay_032'), width: 180 },
        { key: 'arrivalAmount', dataIndex: 'arrivalAmountText', title: i18n.global.t('pages_pay_033'), width: 200 },
        { key: 'status', dataIndex: 'statusText', title: i18n.global.t('pages_pay_114'), width: 100 },
        { key: 'remark', dataIndex: 'remark', title: i18n.global.t('pages_pay_095'), width: 220, ellipsis: true },
        { key: 'applyNo', dataIndex: 'applyNo', title: i18n.global.t('pages_pay_106'), width: 200 },
        { key: 'batchApplyNo', dataIndex: 'batchApplyNo', title: i18n.global.t('pages_pay_112'), width: 200 },
        { key: 'merSingleNo', dataIndex: 'merSingleNo', title: i18n.global.t('pages_pay_113'), width: 200 },
        { key: 'createTime', dataIndex: 'createTime', title: i18n.global.t('pages_pay_077'), width: 200 },
        { key: 'payTime', dataIndex: 'payTime', title: i18n.global.t('pages_pay_115'), width: 200 },
        { key: 'edit', title: i18n.global.t('pages_exchange_056'), width: localStorage.getItem('locale-language') === 'en' ? 328 : 200, align: 'center', fixed: 'right' }
      ]
    })

    state.statusOpts = computed(() => {
      return getAttrStatusList('confirmStatus')
    })

    const showDetail = (record) => {
      state.applyNo = record.applyNo
      state.visible = true
    }

    const updatePage = (page, pageSize) => {
      state.searchQuery.pageNum = page
      state.searchQuery.pageSize = pageSize
      loadSettlesList()
    }

    const exportDetail = async () => {
      await _pay.exportSettlesDetail(getParams())
    }

    const exportElectronicReceipt = async () => {
      await _pay.batchExportElectronicReceipt(getParams())
    }

    const downElectronicReceipt = (record) => {
      const { batchApplyNo, merSingleNo, applyNo } = record
      _pay.downElectronicReceipt({
        batchNo: batchApplyNo,
        merSingleNo,
        applyNo
      })
    }

    const loadSettlesList = async (pageNum) => {
      state.loading = true
      if (pageNum) state.searchQuery.pageNum = pageNum
      const res = await _pay.queryOrderPaymentList(getParams())
      if (res.data.succ) {
        state.settles = res.data.data.list.map(item => {
          return Object.assign({}, item, {
            statusText: getTradeStatusName(item.status, 'confirmStatus'),
            createTime: moment(item.createTime).format('YYYY-MM-DD HH:mm:ss'),
            payTime: item.payTime ? moment(item.payTime).format('YYYY-MM-DD HH:mm:ss') : '',
            remitAmountText: item.remitAmt ? `${item.remitCurrency} ${parseMoney(item.remitAmt)}` : '-',
            arrivalAmountText: item.arrivalAmt ? `${item.arrivalCurrency} ${parseMoney(item.arrivalAmt)}` : '-',
            feeAmtText: `${item.feeCurrency} ${parseMoney(item.feeAmt)}`
          })
        })
        state.total = res.data.data.total
      }
      state.loading = false
    }

    const getParams = () => {
      if (!state.searchQuery.dateOpts) state.searchQuery.dateOpts = []
      if (!state.searchQuery.payDateOpts) state.searchQuery.payDateOpts = []
      const { dateOpts, payDateOpts } = state.searchQuery
      const query = Object.assign({}, state.searchQuery, {
        beginDate: dateOpts[0] ? `${dateOpts[0]} 00:00:00` : '',
        endDate: dateOpts[1] ? `${dateOpts[1]} 23:59:59` : '',
        createTimeStart: payDateOpts[0] ? `${payDateOpts[0]} 00:00:00` : '',
        createTimeEnd: payDateOpts[1] ? `${payDateOpts[1]} 23:59:59` : ''
      })
      return query
    }

    return {
      showDetail,
      exportDetail,
      updatePage,
      getCountryLogo,
      loadSettlesList,
      exportElectronicReceipt,
      downElectronicReceipt,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#paySettles {
  .search-form {
    margin-top: 8px;
    .part-label {
      width: 108px;
    }
  }
  .table-part {
    margin-top: 12px;
  }
}
</style>
